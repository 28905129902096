














































































































































import Vue from 'vue';
import PatientListItem from '@/components/basic/PatientListItem.vue';
import TextCard from '@/components/cards/TextCard.vue';
import {mapActions, mapGetters} from 'vuex';

export default Vue.extend({
  name: 'PatientList',
  components: { PatientListItem, TextCard },
  props: {
    filter: String,
    history: Array,
    period: Object,
  },
  data() {
    return {
      start: null,
      end: null,
      openedPatientAppointment: null,
      selectedAppointmentTreatments: null,
      isLoading: false
    }
  },
  watch: {
    period: 'updatePatientList'
  },
  mounted() {
    this.updatePatientList();
  },
  computed: {
    ...mapGetters(['analyticPatientsList']),
    list() {
      return this.$store.state.analyticPatients
    }
  },
  methods: {
    ...mapActions([
      'GET_ANALYTIC_PATIENTS',
      'GET_APPOINTMENT_TREATMENTS'
    ]),
    setDateRange() {
      const filter = this.filter === 'all' ? 'month' : this.filter;
      this.start = this.period;
      this.end = filter !== 'week' ? this.$dayjs(this.start).endOf(filter) : this.$dayjs(this.start).weekday(7).endOf('day');
    },
    formatTime(t: number /* seconds */) {
      const isLongerThanOneHour = t >= 3600
      return `${
        this.$dayjs
          .utc(this.$dayjs.duration(t * 1000).asMilliseconds())
          .format(isLongerThanOneHour ? 'hh:mm' : 'mm:ss')
      }${
        isLongerThanOneHour ? 'm' : 's'
      }`
    },
    async updatePatientList() {
      this.setDateRange();

      this.openedPatientAppointment = null
      this.selectedAppointmentTreatments = null
      this.isLoading = true;

      await Promise.all([
        this.GET_ANALYTIC_PATIENTS({
          params: {
            filter: this.filter,
            start: this.$dayjs(this.start).format('YYYY-MM-DDTHH:mm:ss'),
            end: this.$dayjs(this.end).format('YYYY-MM-DDTHH:mm:ss'),
          }
        })
      ]);

      this.isLoading = false;
    },
    async getOpenedAppointmentTreatments(appointmentUuid) {

      this.openedPatientAppointment = null
      this.selectedAppointmentTreatments = null

      if (this.openedPatientAppointment !== appointmentUuid){
        this.openedPatientAppointment = appointmentUuid
        const payload = {
          appointmentUuid: this.openedPatientAppointment
        }

        const data = await this.$store.dispatch('GET_APPOINTMENT_TREATMENTS', payload)

        if (data) {
          this.selectedAppointmentTreatments = data.appointmentTreatments
        }
      }

    }
  }
})
