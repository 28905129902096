















































































import Vue from 'vue'
import { mapActions } from 'vuex'

import DashboardPanel from '@/components/modules/DashboardPanel.vue'

export default Vue.extend({
  name: 'AnalyticsDialog',
  components: { DashboardPanel },
  data() {
    return {
      dialog: true,
      filter: 'week'
    }
  },
  mounted() { this.GET_APPT_START_END_DATES({params: {}}) },
  methods: mapActions(['GET_APPT_START_END_DATES'])
})
