

















import Vue from 'vue'
import {mapActions, mapState} from 'vuex';

import BarChart from '@/components/modules/BarChart.vue'
import PatientList from '@/components/modules/PatientList.vue'
import dayjs from 'dayjs';

export default Vue.extend({
  name: 'DashboardPanel',
  components: { BarChart, PatientList },
  props: {
    filter: String,
    type: String,
  },
  data() {
    return {
      activePeriod: null,
      periods: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8', 'Week 9', 'Week 10', 'Week 11']
    }
  },
  computed: {
    ...mapState({
      history({ history: h }) {
        return this.$_.sortBy(
          h?.filter(({ status: x }) => x === 'completed') ?? [],
          'ended_at'
        )
      }
    }),
    /*history() {
      return this.$_.sortBy(
        this.$store.state.history.filter(({ checkedin_at: x }) => !!x),
        'checkedin_at'
      );
    },*/
    dateFormat() {
      const options = {
        all: 'MMM YY',
        year: 'YYYY',
        month: 'MMM YYYY',
        week: 'MMM DD',
        day: 'MM/DD/YY'
      };
      return options[this.filter];
    }
  },
  created() {
    this.setDateRange();
  },
  watch: {
    filter: 'setDateRange'
  },
  methods: {
    ...mapActions([
      'GET_APPT_START_END_DATES',
    ]),
    async setDateRange() {
      // setting from first appointment starts_at till current date and time

      const first = this.$dayjs(this.$store.state.analyticDates.firstDate)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
      const last = this.$dayjs()
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
      let a;
      let b;
      let c;
      if (['all', 'month'].includes(this.filter)) {
        a = this.$dayjs(first).startOf('M');
        b = this.$dayjs(last).startOf('M');
        c = b.diff(a, 'month');
        console.log({ a, b, c });
      } else if (this.filter === 'week') {
        a = this.$dayjs(first).weekday(1).startOf('d');
        b = this.$dayjs(last).weekday(1).startOf('d');
        c = b.diff(a, 'week');
        console.log({ a, b, c });
      } else if (this.filter === 'year') {
        a = this.$dayjs(first).startOf('y');
        b = this.$dayjs(last).startOf('y');
        c = b.diff(a, 'year');
        console.log({ a, b, c });
      } else if (this.filter === 'day') {
        a = this.$dayjs(first).startOf('D');
        b = this.$dayjs(last).startOf('D');
        c = b.diff(a, 'day');
        console.log({ a, b, c });
      }
      this.setPeriods(a, b, c);
    },
    setPeriods(start, end, numPeriods) {
      if (numPeriods === 1) {
        this.periods = [start];
      };
      const periods = [start];
      [...Array(numPeriods).keys()].forEach(v => {
        const filter = this.filter === 'all' ? 'month' : this.filter;
        const add = this.$dayjs(start).add(v+1, filter);
        periods.unshift(add);
      });
      this.periods = periods;
      console.log({ periods: this.periods })
    }
  },
})
